<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <img src="@/assets/logo.png" class="w-44 h-44 -mt-20" />
    <Login />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/LoginComponent.vue';
import API from '@/API.js';
export default {
  name: 'HomeView',
  components: {
    Login
  },
  created() {
    console.log(this.$route.query.code);
    if(this.$route.query.code){
      let provider=localStorage.getItem(this.$route.query.state);
      API.login(provider, this.$route.query.code, (r)=>{
        if(r.status=="success"){
          this.$router.push('/');
        }
      });
    }
  }
}
</script>

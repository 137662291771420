<template>
    <div class="w-full h-20 bg-white z-10 p-4 flex-grow">     
        <span class="font-bold text-4xl align-middle inline-block">{{ title }}</span>

        <font-awesome-icon icon="bars" class="float-right m-2 inline-block md:hidden text-4xl text-primary cursor-pointer" @click="$emit('toggleMenu')"/>
        <div class="pl-4 hidden">
            <input id="searchinput" class="border-2 inline-block rounded-2xl border-gray-500 placeholder-gray-500 p-2 px-4" type="text" placeholder="Suche"/>
            <div id="searchresults" class="absolute z-20 p-4 border border-gray-200 bg-white shadow-lg rounded-lg hidden font-bold">Die Suche funktioniert noch nicht</div>
        </div>
        <div class="float-right mt-5 mr-5 hidden md:inline-block">
            <span class="px-4 font-bold">Hallo, {{name}}</span>
        </div>
    </div>
</template>
<style scoped>
#searchinput:focus + #searchresults {
    display: block;
}
</style>
<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
    name: 'Header',
    props: ['title'],
    data() {
        return {
            name: ""
        }
    },
    methods: {
    },
    created() {
        API.checkAuth((data)=>{
            if(!data || data.status == "error"){
                this.$router.push('/login')
            }else{
                if(data.name){
                    this.name = data.name
                }else{
                    this.name = data.mail.split("@")[0]
                }
            }
        })
    }
}
</script>
  
<template>
  <div class="h-full bg-white z-20 md:inline-block" :class="expanded?'w-full absolute':'w-64 hidden'">
    <div class="absolute left-0 bottom-0 w-64 text-center">
      <a href="/impressum" class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold">Impressum</a>
      <a href="/datenschutz" class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold">Datenschutz</a>
    </div>
    <div class="relative w-64 h-20 text-center p-2 inline-block">
            <img src="@/assets/logo.png" class="max-w-full p-2 max-h-full inline-block" alt="Rescanner logo" />
            <span class="ml-4 align-middle font-bold text-3xl text-primary">Re</span>
            <span class="font-bold align-middle text-3xl text-black">Scanner</span>
        </div>
    <div class="px-10 mt-10">
      <div v-if="expanded" class="w-full text-right ">
        <font-awesome-icon icon="arrow-left" class="text-3xl text-primary cursor-pointer" @click="expanded = false; takeAll = false" />
      </div>
      <span class="font-bold text-2xl">Verwaltung</span>
      <div>
        <div class="inline-block pt-3" >
          <a href="/" class="text-lg font-bold border-b-4 hover:border-primarylight" :class="title === 'Home' ? 'border-primary' : 'border-white'">Home</a>
        </div>
      </div>
      <div>
        <div class="inline-block pt-3" >
          <a href="/devices" class="text-lg font-bold border-b-4 hover:border-primarylight" :class="title === 'Geräte' ? 'border-primary' : 'border-white'">Geräte</a>
        </div>
      </div>
    </div>
    <div class="px-10 mt-10">
      <span class="font-bold text-2xl">Apps</span>
      <div>
        <div class="inline-block pt-3" >
          <a href="/apps/timetracking" class="text-lg font-bold border-b-4 hover:border-primarylight" :class="title === 'Zeiterfassung' ? 'border-primary' : 'border-white'">Zeiterfassung</a>
        </div>
      </div>
    </div>
    <div class="absolute bottom-10 left-0 px-10">
      <div>
        <div class="inline-block pt-3" >
          <a href="/account" class="text-lg font-bold border-b-4 hover:border-primarylight" :class="title === 'Mein Account' ? 'border-primary' : 'border-white'">Mein Account</a>
          <br><button class="full" @click="logout">Abmelden</button>

        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
  name: 'Header',
  props: ['title'],
  data() {
    return {
      expanded: false
    }
  },
  exposed: ['toggleMenu'],
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded
    },
    logout(){
            API.logout((r)=>{
                if(r.status=="success"){
                    this.$router.push('/login')
                }
            })
        }
  }
}
</script>
  
<template>
  <div class="w-full h-full bg-black/40 absolute top-0 left-0 text-center" v-if="registeringUser">
    <div class="bg-white rounded-lg p-4 w-1/2 inline-block m-4 text-left">
      <span class="font-bold text-xl">Karte neu verbinden</span>
      <div class="rounded border-2 border-gray-200 p-2 float-right cursor-pointer align-top" @click="registeringUser = null">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 hover:transform">
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
      </div>
      <span class="block font-bold mt-3">Gerät auswählen</span>
      <select v-model="registrationDevice" @change="setDevice(registrationDevice)">
        <option value="">Bitte auswählen</option>
        <option v-for="device in devices" :key="device.id" :value="device.id">{{ device.name }}</option>
      </select>
      <div v-if="waitingForCard" class="text-center mt-4">
        <span class="font-bold">Warte auf Karte...</span><br>
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <div class="bg-white rounded-lg p-4 max-h-full flex flex-col">
    <div>
      <span class="font-bold text-xl">Nutzer</span><button v-if="users.filter(d => !d.id).length == 0" class="scale-75" @click="addUserSection">Hinzufügen</button>
      <div class="rounded border-2 border-gray-200 p-2 float-right cursor-pointer" @click="getUsers">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="inline-block w-5 align-top pt-0.5">
          <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
        </svg>
      </div>
    </div>
    <div class="overflow-y-auto mt-4">
      <div v-for="user in users" :key="user.id" :ref="'dev' + user.id" class="mb-4 border-2 border-gray-200 p-2">
        <div>
          <div class="flex flex-row px-4">
            <input class="headerinput pl-0" placeholder="Name" v-model="user.name" @change="updateUser(user)" />
            <div class="flex-grow text-right pt-1 cursor-pointer" @click="clickExpand($event.target, user)">
              <span v-if="user.status=='Eingestempelt'" class="bg-green-400 px-2 py-1 ml-2 text-black font-bold inline-block rounded-lg">Eingestempelt</span>
              <span v-else class="bg-red-400 px-2 py-1 ml-2 text-black font-bold inline-block rounded-lg">{{user.status}}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-6 ml-5 text-primary inline-block">
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
              </svg>
            </div>
          </div>
          <div class="px-4 text-sm">
            Zuletzt gestempelt: {{ formatDate(user.lastSeen) }}
          </div>
          <div v-show="user.visible" class="px-4">
            <div class="grid grid-cols-3">
              <div class="inline-block align-top mr-4">
                <label class="mt-4">Wochenstunden:</label>
                <input type="number" placeholder="38,5" step="0.1" v-model="user.weekHours" class="w-24"> <b>Stunden / Woche</b>
              </div>
              <div class="inline-block align-top mr-4">
                <label class="mt-4">Überstundenkonto:</label>
                <input placeholder="38,5"  v-model="user.overtime" class="w-24" disabled> <b>Stunden</b>
              </div>
              <div class="inline-block align-top mr-4">
                <label class="mt-4">Karten-ID:</label>
                <input placeholder="AF0D5D9CC6" v-model="user.cardId" class="w-36 mr-2 "><button class="inline-block yellow" @click="registerCard(user)">Mit Gerät auslesen</button>
              </div>
              <div class="inline-block align-top mr-4">
                <label class="mt-4">Startdatum:</label>
                <input v-model="user.startDate" type="date" class="w-36 mr-2 ">
              </div>
            </div>
            <div class="w-full text-right mt-4">
              <button class="inline-block red mr-2" @click="deleteUser(user)">Löschen</button>
              <button class="inline-block yellow mr-2" @click="showUserActivity=user">Kalender anzeigen</button>
              <button v-if="user.id" class="inline-block" @click="updateUser(user)">Speichern</button>
              <button v-else class="inline-block" @click="updateUser(user)">Erstellen</button>
            </div>
            <TTActivity v-if="showUserActivity==user" :user="user" class="mt-4"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4CAF50;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<script>
// @ is an alias to /src
import API from '@/API.js'
import TTActivity from '@/components/timetracking/TTActivity.vue';

export default {
  name: 'TimeTrackingUser',
  components: {
    TTActivity
  },
  data() {
    return {
      users: [],
      visibleUsers: [],
      registeringUser: null,
      registrationDevice: "",
      devices: [],
      waitingForCard: false,
      showUserActivity: false
    }
  },
  methods: {
    registerCard(user) {
      this.registeringUser = user;
      this.getDevices();
    },
    setDevice(device) {
      this.waitingForCard = true
      API.registerCard(device, this.registeringUser, () => { })
      let registerInterval = setInterval(() => {
        if (this.waitingForCard && this.registrationDevice == device && this.registeringUser != null) {
          API.registerCard(device, this.registeringUser, (data) => {
            if (data && data.status == "registered") {
              this.waitingForCard = false
              this.registeringUser = null
              this.getUsers()
            }
          })
        }
        else {
          clearInterval(registerInterval)
        }
      }, 5000)
    },
    addUserSection() {
      let vm = this;
      API.getCompany((data) => {
        console.log(data)
        if (data && !data.error) {
          vm.users.push({})
        } else {
          vm.$router.push({ path: '/account' })
        }
      })
    },
    getUsers() {
      API.getTTUsers((data) => {
        if (!data.error) {
          let newUsers = this.users.filter(d => !d.id)
          this.users = data
          this.users.push(...newUsers)
          this.users.forEach((user) => {
            if (this.visibleUsers.includes(user.id)) {
              user.visible = true
            }
          })
        }
      })
    },
    deleteUser(user) {
      if (user.id) {
        API.deleteUser(user, (r) => { })
        this.visibleUsers.splice(this.visibleUsers.indexOf(user.id), 1)
      }
    },

    updateUser(user) {
      if (user.id) {
        API.updateTTUser(user, (r) => {
          this.getUsers();
        })
      } else {
        API.createTTUser(user, (r) => {
          this.users.splice(this.users.indexOf(user), 1)
          this.getUsers();
        })
      }
    },
    clickExpand(svg, user) {
      console.log(svg.tagName)
      if (svg.tagName == "path") svg = svg.parentNode
      if (svg.tagName == "DIV") svg = svg.querySelector("svg")
      console.log(svg)
      setTimeout(() => {
        if(user.visible){
          this.visibleUsers.splice(this.visibleUsers.indexOf(user.id), 1)
        }else{
          this.visibleUsers.push(user.id)
        }
        user.visible = !user.visible
      }, 500)
      const currentTransform = svg.getAttribute('transform');
      console.log(currentTransform)
      if (currentTransform === 'rotate(90)') {
        svg.setAttribute('transform', 'rotate(0)');
      } else {
        svg.setAttribute('transform', 'rotate(90)');
      }
    },
    getDevices() {
      API.getDevices((data) => {
        if (!data.error) {
          this.devices = data
        }
      })
    },
    formatDate(date) {
      let d = new Date(date);
      let today = new Date();
      if (d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()) {
        return d.toLocaleString().split(", ")[1]
      }
      if (d.toLocaleDateString() == "1.1.1970" || "Invalid Date") {
        return "noch nie";
      }
      return d.toLocaleDateString()
    }
  },
  mounted() {
    this.getUsers()
    //setInterval(() => {
    //  this.getUsers()
    //},10000)
  }
}
</script>
  
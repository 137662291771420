<template>
  <div class="w-full h-full">
    <Header title="Home" />
    <Navigation title="Mein Account" />
    <div id="content" class="w-full h-full pl-64 pt-20">
      <div class="w-full h-full p-4">
        <Account /><br>
        <Company />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Company from '@/components/Company.vue';
import Account from '@/components/Account.vue';
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'
export default {
  name: 'HomeView',
  components: {
    Navigation,
    Header,
    Company,
    Account
  }
}
</script>

<template>
  <div class="w-full h-full">
    <div class="w-full h-12 p-3 bg-white">
      <img src="@/assets/logo.png" class="max-w-full max-h-full inline-block mr-10" alt="Rescanner logo" />
      <span class="font-bold text-2xl align-top inline-block">Zeiterfassung</span>
    </div>
    <div v-if="userInfo" class="absolute bg-black/50 w-full h-full z-10 text-center">
      <div class="bg-white rounded-lg inline-block p-4 py-6 w-2/3 mt-10 relative">
        <div class="rounded border-2 border-gray-200 p-2 absolute top-3 right-3 cursor-pointer " @click="userInfo = null">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 hover:transform">
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div v-if="userInfo.status == 'info'" class="text-left text-xl">
          <span class="font-bold text-2xl pr-8">Informationen für {{ userInfo.name }}</span>
          <div class="w-full grid grid-cols-2 font-bold mt-4">
            <span>Überstunden:</span>
            <span>{{ userInfo.overtime }}h</span>
            <span>Übrige Urlaubstage:</span>
            <span>{{ userInfo.vacationDays }}</span>
          </div>
          <div v-if="userInfo.nextScheduledVacations.length > 0">
            <span class="font-bold">Geplante Abwesenheiten:</span>
            <div class="ml-4">
              <ul class="list-disc font-bold ml-4">
                <li v-for="vacation in userInfo.nextScheduledVacations" :key="vacation">
                  {{ vacation.start }} - {{ vacation.end }}: Urlaub ({{ vacation.days }} Tag(e))
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="font-bold text-xl">{{ userInfo.message }}</span><br>
          <button v-if="userInfo.status == 'success'" class="red-full mt-5" @click="deleteLastBooking()">Buchung rückgängig machen</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 text-center w-full mt-10">
      <div @click="mode = 'checkin'" class="flex items-center justify-center">
        <div class="bg-white rounded-lg shadow-lg p-4 inline-block duration-300 transform" :class="mode === 'checkin' ? 'scale-125 fill-primary' : 'scale-100 fill-gray-400'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-28 h-28 ">
            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
          </svg>
        </div>
      </div>
      <div @click="mode = 'checkout'" class="flex items-center justify-center">
        <div class="bg-white rounded-lg shadow-lg p-6 inline-block duration-300 transform" :class="mode === 'checkout' ? 'scale-125 fill-primary' : 'scale-100 fill-gray-400'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-24 h-24">
            <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
          </svg>
        </div>
      </div>
      <div @click="mode = 'info'" class="flex items-center justify-center">
        <div class="bg-white rounded-lg shadow-lg p-8 inline-block duration-300 transform" :class="mode === 'info' ? 'scale-125 fill-primary' : 'scale-100 fill-gray-400'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-20 h-20">
            <path
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mt-8 text-center">
      <span class="font-bold text-xl">Bitte Karte auflegen...</span>
    </div>
  </div>
</template>

<script>
import http from '@/lib/http'// @ is an alias to /src
export default {
  name: 'TimeTrackingUI',
  data() {
    return {
      mode: 'checkin',
      userInfo: null,
      lastNFC: ""
    }
  },
  methods: {
    nfcScanned(message) {
      this.lastNFC = message
      http.change("/apps/timetracking/ui/nfcevent", "POST", {
        data: message,
        mode: this.mode,
        token: window.rescannertoken
      }, (response) => {
        this.setUserInfo(response, response.status=="info")
      })
    },
    deleteLastBooking() {
      http.change("/apps/timetracking/ui/nfcevent/delete", "POST", {
        token: window.rescanner.token,
        data: this.lastNFC
      }, (response) => {
        this.setUserInfo(response)
      })
    },
    setUserInfo(info, long=false) {
      this.userInfo = info,
        console.log(info)
      setTimeout(() => {
        this.userInfo = null
      }, long?10000:2000)
    },
    loadRescanner() {
      let vm = this
      if (window.rescanner) {
        window.rescanner.onNFCSuccess = (message) => {
          vm.nfcScanned(message);
        }
        window.rescanner.onNFCError = (message) => {
          this.setUserInfo({status: "error",message: message})
        }
      }
      if (!window.rescanner) {
        let rescannerLib = document.createElement('script')
        rescannerLib.setAttribute('src', 'https://cdn.rescanner.de/rescanner.js')
        rescannerLib.onload = () => {
          vm.loadRescanner()
        }
        document.head.appendChild(rescannerLib);
      }
    }
  },

  mounted() {
    let vm = this;
    this.loadRescanner()
  }
}
</script>

const get=require('./lib/http').get
const change=require('./lib/http').change
function checkAuth(cb) {
    get('/user', cb)
}

function login(provider, code, cb) {
    change('/user/login', 'POST', { code: code, provider: provider }, cb)
}

function logout(cb) {
    change('/user/logout', 'POST', {}, cb)
}

function updateUser(user, cb) {
    change('/user', 'PUT', user, cb)
}

function getCompany(cb) {
    get('/company', cb)
}

function updateCompany(company, cb) {
    change('/company', 'PUT', company, cb)
}

function addUserToCompany(user, cb) {
    change('/company/users', 'POST', user, cb)
}

function removeUserFromCompany(user, cb) {
    change('/company/users/'+user.id, 'DELETE',{}, cb)
}

function getDevices(cb) {
    get('/devices', cb)
}

function getDeviceEvents(device,cb){
    get('/devices/'+device.id+'/events', cb)
}

function updateDevice(device, cb) {
    change('/devices/'+device.id, 'PUT', device, cb)
}

function createDevice(device, cb) {
    change('/devices', 'POST', device, cb)
}

function deleteDevice(device, cb) {
    change('/devices/'+device.id, 'DELETE', {}, cb)
}

function registerCard(deviceId, user, cb) {
    change('/apps/timetracking/registerCard', 'POST', {userId: user.id, deviceId: deviceId}, cb)
}
function createTTUser(user, cb) {
    change('/apps/timetracking/users', 'POST', user, cb)
}
function updateTTUser(user, cb) {
    change('/apps/timetracking/users/'+user.id, 'PUT', user, cb)
}

function getTTUsers(cb) {
    get('/apps/timetracking/users', cb)
}

function getTTEventsForRange(ttuser, start, end, cb) {
    get('/apps/timetracking/users/'+ttuser.id+'/events?start='+start.toISOString()+'&end='+end.toISOString(), cb)
}

function addTTEvent(ttuser, event, cb) {
    change('/apps/timetracking/users/'+ttuser.id+'/events', 'POST', {
        timestamp: event.timestampHTML,
        start:event.start,
        type: event.type
    }, cb)
}

function deleteTTEvent(ttuser, event, cb) {
    change('/apps/timetracking/users/'+ttuser.id+'/events/'+event.id, 'DELETE', {}, cb)
}

function updateTTEvent(ttuser, event, cb) {
    change('/apps/timetracking/users/'+ttuser.id+'/events/'+event.id, 'PUT', {
        timestamp: event.timestampHTML,
        start:event.start,
        type: event.type
    }, cb)
}

module.exports = {
    checkAuth,
    login,
    logout,
    updateUser,
    getCompany,
    updateCompany,
    addUserToCompany,
    removeUserFromCompany,
    getDevices,
    updateDevice,
    createDevice,
    deleteDevice,
    registerCard,
    createTTUser,
    updateTTUser,
    getTTUsers,
    getTTEventsForRange,
    addTTEvent,
    updateTTEvent,
    deleteTTEvent,
    getDeviceEvents
}
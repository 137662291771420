<template>
    <div>
        <div v-if="editDay" class="w-full h-full absolute bg-black/50 top-0 left-0 text-center">
            <div class="bg-white rounded-lg mt-4 p-4 w-1/2 inline-block text-left">
                <span class="font-bold text-xl">Einträge für den {{ editDay.datestring }} bearbeiten</span>
                <div class="rounded border-2 border-gray-200 p-2 float-right cursor-pointer align-top" @click="editDay = null">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 hover:transform">
                        <path
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                </div>
                <div class="mt-3">
                    <div v-for="event in editDay.events" :key="event.id" class="border-b-2 border-gray-200 pb-3">
                        <div class="inline-block align-top pr-2">
                            <label>Beginn / Ende</label>
                            <select v-model="event.start">
                                <option :value="true">Beginn</option>
                                <option :value="false">Ende</option>
                            </select>
                        </div>
                        <div v-if="event.start" class="inline-block">
                            <label>Typ</label>
                            <select v-model="event.type">
                                <option value="A">Arbeitszeit</option>
                                <option value="U">Urlaub</option>
                                <option value="K">Krank</option>
                            </select>
                        </div>
                        <div class="inline-block">
                            <div class="inline-block">
                                <label>Zeit</label>
                                <input type="datetime-local" :min="editDay.dateHTML" v-model="event.timestampHTML" @change="event.timestampTime = (new Date(event.timestampHTML)).toLocaleTimeString().substring(0, 5)">
                            </div>

                            <button class="red" @click="deleteEvent(event)">Löschen</button>
                        </div>
                    </div>
                    <button class="mt-3" @click="editDay.events.push({})">Eintrag hinzufügen</button>
                </div>
                <div class="mt-3 w-full text-right">
                    <button @click="saveDate()">Speichern</button>
                </div>
            </div>
        </div>
        <div>
            <div class="flex justify-between items-center mb-4">
                <button @click="previousWeek">&lt;</button>
                <h2 class="text-lg font-bold">{{ weekHeader }}</h2>
                <button @click="nextWeek">&gt;</button>
            </div>
            <div class="grid grid-cols-7 gap-2">
                <div v-for="day in weekDays" :key="day" class="text-left font-bold">
                    {{ day }}
                </div>
                <div v-for="(date, index) in weekDates" :key="index" class="text-left p-2 border border-gray-200 cursor-pointer" @click="editDay = date"
                    :class="index > 4 ? 'bg-gray-200' : date.datestring == (new Date).toLocaleDateString() ? 'bg-green-50' : 'bg-white'">
                    <b>{{ date.datestring }}</b> ({{ date.sum }})
                    <div v-for="(event, index) in date.events" :key="event.id">
                        <div v-if="index > 0 && event.start && !date.events[index - 1].start" class="border-b border-gray-200 py-2">
                            <span class="p-1 rounded text-sm font-bold" :class="typeColors['P']">P</span>
                            {{ date.events[index - 1].timestampTime }} - {{ event.timestampTime }} <span>({{ calcDuration(date.events[index - 1].timestampHTML, event.timestampHTML) }}h)</span>
                        </div>
                        <div v-if="event.start && index < date.events.length - 1 && !date.events[index + 1].start" class="border-b border-gray-200 py-2">
                            <span class="p-1 rounded text-sm font-bold" :class="typeColors[event.type]">{{ event.type }}</span>
                            {{ event.timestampTime }} - {{ date.events[index+1].timestampTime }} <span class="whitespace-nowrap">({{ calcDuration(event.timestampHTML, date.events[index+1].timestampHTML) }} h)</span>
                        </div>
                        <div v-else-if="event.start" class="border-b border-gray-200 py-2">
                            <span class="p-1 rounded text-sm font-bold" :class="typeColors[event.type]">{{ event.type }}</span>
                            {{ event.timestampTime }} -
                        </div>
                        <div v-else-if="index > 0 && !event.start && !date.events[index - 1].start" class="border-b border-gray-200 py-2">
                            <span class="p-1 rounded text-sm font-bold" :class="typeColors[event.type]">{{ event.type }}</span>
                            - {{ event.timestampTime }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 font-bold">
                <span class="bg-green-200 p-1 rounded  text-sm font-bold">A</span>: Arbeitszeit
                <span class="bg-yellow-200 p-1 rounded  text-sm font-bold">P</span>: Pause
                <span class="bg-blue-200 p-1 rounded  text-sm font-bold">U</span>: Urlaub
                <span class="bg-red-200 p-1 rounded  text-sm font-bold">K</span>: Krank
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
    name: 'UserActivity',
    props: ["user"],
    data() {
        return {
            editDay: false,
            typeColors: {
                A: "bg-green-200",
                P: "bg-yellow-200",
                U: "bg-blue-200",
                K: "bg-red-200"
            },
            weekHeader: "",
            weekDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',"So"], // Days of the week in German
            weekDates: [], // Populate with dates for the current week
            startDate: new Date(),
            endDate: new Date(),
            eventsToDelete: [],
        };
    },
    methods: {
        deleteEvent(event) {
            this.eventsToDelete.push(event)
            this.editDay.events.splice(this.editDay.events.indexOf(event), 1)
        },
        calcDuration(start, end) {
            let durationInHours = (new Date(end) - new Date(start)) / (1000 * 60 * 60);
            let hours = Math.floor(durationInHours);
            let minutes = Math.round((durationInHours % 1) * 60);
            if (isNaN(hours)) return "";
            return `${hours}:${minutes.toString().padStart(2, '0')}`;
        },
        nextWeek() {
            this.startDate.setDate(this.startDate.getDate() + 7);
            this.endDate.setDate(this.endDate.getDate() + 7);
            this.updateWeekHeader()
            this.getTTEventsForCurrentDates();
        },
        previousWeek() {
            this.startDate.setDate(this.startDate.getDate() - 7);
            this.endDate.setDate(this.endDate.getDate() - 7);
            this.updateWeekHeader()
            this.getTTEventsForCurrentDates();
        },
        updateWeekHeader() {
            let firstDayOfYear = new Date(this.startDate.getFullYear(), 0, 1);
            let pastDaysOfYear = (this.startDate - firstDayOfYear) / 86400000;
            let currentWeekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
            let formattedMonday = this.startDate.toLocaleDateString('de-DE');
            let formattedSaturday = this.endDate.toLocaleDateString('de-DE');
            this.weekHeader = `KW ${currentWeekNumber} (${formattedMonday} - ${formattedSaturday})`;
        },
        getTTEventsForCurrentDates() {
            API.getTTEventsForRange(this.user, this.startDate, this.endDate, (data) => {
                this.weekDates = data
                
            })
        },

        addTTEvent(event) {
            API.addTTEvent(this.user, event, (data) => {
            })
        },
        updateTTEvent(event) {
            API.updateTTEvent(this.user, event, (data) => {
            })
        },
        saveDate() {
            this.editDay.events.forEach(event => {
                if (event.id) {
                    this.updateTTEvent(event)
                }
                else {
                    this.addTTEvent(event)
                }
            })
            this.eventsToDelete.forEach(event => {
                API.deleteTTEvent(this.user, event, (data) => {
                })
            })
            this.editDay = false
            setTimeout(() => {
                this.getTTEventsForCurrentDates()
            }, 300)
        }

    },
    mounted() {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + (this.startDate.getDay() === 0 ? -6 : 1));
        this.endDate = new Date(this.startDate)
        this.endDate.setDate(this.endDate.getDate() + 6);
        this.updateWeekHeader();
        this.getTTEventsForCurrentDates(new Date());
    },
}
</script>
function get(path, callback) {
    var req = new XMLHttpRequest()
    req.open("GET", process.env.VUE_APP_BE_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            let response=JSON.parse(req.responseText)
            callback(response)
        } catch (e) {
            callback(false)
        }
    })
    req.onerror = function (event) {
        callback(false)
    }
    req.send()
}
function change(path, method, data, callback) {
    var req = new XMLHttpRequest()
    req.open(method, process.env.VUE_APP_BE_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            let response=JSON.parse(req.responseText)
            callback(response)
        } catch (e) {
            callback(false)
        }
    })
    req.onerror = function (event) {
        callback(false)
    }
    req.send(JSON.stringify(data))
}

module.exports = {
    get,
    change
}
<template>
  <div class="bg-white rounded-lg p-4">
    <span class="font-bold text-xl">Mein Unternehmen</span>
    <div v-if="!company.id" class="bg-red-100 p-2 mt-2 font-bold rounded-lg">
      Um ein Gerät zu erstellen, muss zuerst ein Unternehmen angelegt werden.
    </div>
    <div class="mt-4">
      <label>Name:</label>
      <input type="text" class="w-1/2" placeholder="Name" v-model="company.name" />
      <div v-if="company.id">
        <label class="mt-4">Mitarbeiter:</label>
        <div v-for="user in company.users" :key="user.id" class="mb-2">
          <div v-if="user.id">
            <input type="text" class="mr-3 w-1/2" v-model="user.mail" disabled />
            <button class="red" @click="removeUserFromCompany(user)">Löschen</button>
          </div>
          <div v-else>
            <input type="text" class="mr-3 w-1/2" placeholder="Mitarbeiter hinzufügen (E-Mail)" v-model="user.mail" />
            <button @click="addUserToCompany(user)">Hinzufügen</button>
          </div>
        </div>
      </div>
      <div class="w-full text-right mt-4">
        <button v-if="company.id" class="inline-block" @click="updateCompany">Speichern</button>
        <button v-else class="inline-block" @click="updateCompany">Erstellen</button>
      </div>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
  name: 'Devices',
  data() {
    return {
      user: {},
      company: {},

    }
  },
  methods: {
    getCompany() {
      API.getCompany((data) => {
        if (data) {
          this.company = data
          this.company.users.push({ mail: "" })
        }
      })
    },
    updateCompany() {
      API.updateCompany(this.company, (r) => {
          location.reload()
      })
    },
    addUserToCompany(user) {
      API.addUserToCompany(user, (r) => {
          location.reload()
      })
    },
    removeUserFromCompany(user) {
      API.removeUserFromCompany(user, (r) => {
          location.reload()
      })
    }
  },
  created() {
    this.getCompany()
  }
}
</script>
  
<template>
  <div class="bg-white rounded-lg p-4 max-h-full flex flex-col">
    <div>
      <span class="font-bold text-xl">Geräte</span><button v-if="devices.filter(d => !d.id).length == 0" class="scale-75" @click="addDeviceSection">Hinzufügen</button>
      <div class="rounded border-2 border-gray-200 p-2 float-right cursor-pointer" @click="getDevices">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="inline-block w-5 align-top pt-0.5">
          <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
        </svg>
      </div>
    </div>
    <div class="overflow-y-auto mt-4">
      <div v-for="device in devices" :key="device.slug" :ref="'dev' + device.id" class="mb-4 border-2 border-gray-200 p-2">
        <div>
          <div class="flex flex-row px-4">
            <input class="headerinput pl-0 !w-2/3" placeholder="Gerätename" v-model="device.name" @change="updateDevice(device)" />
            <div class="flex-grow text-right pt-1 cursor-pointer" @click="clickExpand($event.target, device)">
              <span v-if="device.lastSeen && Date.now() - new Date(device.lastSeen) < 60000" class="bg-green-400 px-2 py-1 ml-2 text-black font-bold inline-block rounded-lg">Online</span>
              <span v-else class="bg-red-400 px-2 py-1 ml-2 text-black font-bold inline-block rounded-lg">Offline</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-6 ml-5 text-primary inline-block">
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
              </svg>
            </div>
          </div>
          <div v-show="device.authcode" class="bg-red-400 px-2 py-1 ml-4 text-black font-bold inline-block rounded-lg">Authcode: {{ device.authcode }}</div>
          <div v-if="device.lastSeen && Date.now() - new Date(device.lastSeen) > 60000" class="px-4 text-sm">
            Zuletzt gemeldet: {{ formatDate(device.lastSeen) }}
          </div>
          <div v-show="device.visible" class="px-4">
            <div class="lg:flex lg:flex-row">
              <div class="align-top mr-4">
                <label class="mt-4">Anzeige:</label>
                <select v-model="device.display">
                  <option value="">Bitte auswählen</option>
                  <option value="timetracking">Zeiterfassung</option>
                  <option value="customurl">Eigene URL</option>
                </select>
              </div>
              <div v-show="device.display == 'customurl'" class="flex-grow flex-col">
                <div class="w-full flex flex-row">
                  <div class="flex-grow pr-3">
                    <label class="mt-4">URL:</label>
                    <input type="text" class="w-full" placeholder="https://deine.url.de/" v-model="device.url" />
                  </div>
                  <div class="flex justify-end items-end mb-1">
                    <label class="switch">
                      <input type="checkbox" v-model="device.basicAuth">
                      <span class="slider"></span>
                    </label><span class="align-top font-bold text-lg ml-2 inline-block">Basic Auth</span>
                  </div>
                </div>
                <div v-if="device.basicAuth" class="align-top">
                  <div class="mr-4 inline-block">
                    <label>Username:</label>
                    <input type="text" class="w-full lg:w-96" placeholder="Username" v-model="device.username" />
                  </div>
                  <div class="inline-block">
                    <label class="mt-4">Passwort:</label>
                    <input type="password" class="w-full lg:w-96" placeholder="Passwort" v-model="device.password" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="device.events">
              <label class="mt-4">Aktivität:</label>
              <table class="w-full md:w-1/2 max-h-80 overflow-y-auto">
                <thead>
                  <tr>
                    <th>Typ</th>
                    <th>Daten</th>
                    <th>Datum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="event in device.events" :key="event.id">
                    <td>{{ event.type }}</td>
                    <td>{{ event.data }}</td>
                    <td>{{ formatDate(event.createdAt) }}</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
            <div class="w-full text-right mt-4">
              <button class="inline-block red mr-2" @click="deleteDevice(device)">Löschen</button>
              <button v-if="device.id" class="inline-block yellow mr-2" @click="getDeviceEvents(device)">Aktivitäten </button>
              <button v-if="device.id && !device.authcode" class="inline-block yellow mr-2" @click="generateAuthcode(device)">Verbindungscode erstellen</button>
              <button v-else-if="device.id" class="inline-block yellow mr-2" @click="removeAuthcode(device)">Code löschen</button>
              <button v-if="device.id" class="inline-block mr-2" @click="updateDevice(device)">Speichern</button>
              <button v-else class="inline-block" @click="updateDevice(device)">Erstellen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
  name: 'Devices',
  data() {
    return {
      devices: [],
      visibleDevices: []
    }
  },
  methods: {
    addDeviceSection() {
      let vm = this;
      API.getCompany((data) => {
        console.log(data)
        if (data && !data.error) {
          vm.devices.push({ display: "timetracking", lastSeen: (new Date()).toISOString() })
        } else {
          vm.$router.push({ path: '/account' })
        }
      })
    },
    getDeviceEvents(device){
      API.getDeviceEvents(device, (data) => {
        device.events = data
      })
    },
    getDevices() {
      API.getDevices((data) => {
        if (!data.error) {
          let newDevices = this.devices.filter(d => !d.id)
          this.devices = data
          this.devices.push(...newDevices)
          this.devices.forEach((device) => {
            if (this.visibleDevices.includes(device.id)) {
              device.visible = true
            }
          })
        }
      })
    },
    deleteDevice(device) {
      if (device.id) {
        API.deleteDevice(device, (r) => {
          this.getDevices()
        })
        this.visibleDevices.splice(this.visibleDevices.indexOf(device.id), 1)
      }
    },
    generateAuthcode(device) {
      device.authcode = this.generateRandomString(6)
      API.updateDevice(device, (r) => { })
    },
    removeAuthcode(device) {
      device.authcode = ""
      API.updateDevice(device, (r) => { })
    },
    updateDevice(device) {
      if (device.id) {
        API.updateDevice(device, (r) => {
          this.getDevices()
        })
      } else {
        API.createDevice(device, (r) => {
          this.devices.splice(this.devices.indexOf(device), 1)
          this.getDevices()
        })
      }
    },
    clickExpand(svg, device) {
      console.log(svg.tagName)
      if (svg.tagName == "path") svg = svg.parentNode
      if (svg.tagName == "DIV") svg = svg.querySelector("svg")
      console.log(svg)
      setTimeout(() => {
        if (device.visible) {
          this.visibleDevices.splice(this.visibleDevices.indexOf(device.id), 1)
        } else {
          this.visibleDevices.push(device.id)
        }
        device.visible = !device.visible
      }, 500)
      const currentTransform = svg.getAttribute('transform');
      console.log(currentTransform)
      if (currentTransform === 'rotate(90)') {
        svg.setAttribute('transform', 'rotate(0)');
      } else {
        svg.setAttribute('transform', 'rotate(90)');
      }
    },
    generateRandomString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    formatDate(date) {
      let d = new Date(date);
      if (d.toLocaleDateString() == "1.1.1970") {
        return "noch nie";
      }
      return d.toLocaleString()
    }
  },
  mounted() {
    this.getDevices()
    //setInterval(() => {
    //  this.getDevices()
    //},10000)
  }
}
</script>
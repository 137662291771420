<template>
  <div class="w-full h-full flex flex-row">
    <Navigation title="Geräte" ref="nav"/>
    <div class="flex flex-grow flex-col relative">
      <Header title="Geräte" class="flex-grow" @toggleMenu="$refs.nav.toggleMenu()"/>
      <div id="content" class="w-full h-full overflow-y-auto">
        <div class="w-full h-full p-4">
          <Devices />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Devices from '@/components/Devices.vue';
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'
export default {
  name: 'HomeView',
  components: {
    Navigation,
    Header,
    Devices
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import TimeTrackingUser from '../views/TimeTrackingView.vue'
import TimeTrackingUI from '../views/TimeTrackingUI.vue'
import MainView from '../views/MainView.vue'
import LoginView from '../views/LoginView.vue'
import AccountView from '../views/AccountView.vue'
import DeviceView from '../views/DeviceView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/apps/timetracking',
    name: 'timetracking',
    component: TimeTrackingUser
  },
  {
    path: '/apps/timetracking/ui',
    name: 'timetrackingUI',
    component: TimeTrackingUI
  }
  ,{
    path: '/login',
    name: 'login',
    component: LoginView
  },{
    path: '/devices',
    name: 'devices',
    component: DeviceView
  },{
    path: '/account',
    name: 'account',
    component: AccountView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

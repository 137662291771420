<template>
  <div class="bg-white rounded-lg p-4">
    <span class="font-bold text-xl">Mein Account</span>
    <div class="mt-4">
      <label>Name:</label>
      <input type="text" class="w-1/2" placeholder="Name" v-model="user.name" />
      <label class="mt-2">E-Mail:</label>
      <input type="text" class="w-1/2" placeholder="Name" v-model="user.mail" disabled/>
      <div class="w-full text-right mt-4">
        <button class="inline-block" @click="updateUser">Speichern</button>
      </div>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import API from '@/API.js'
export default {
  name: 'Devices',
  data() {
    return {
      user: {}
    }
  },
  methods: {
    getUser() {
      API.checkAuth((data) => {
        this.user = data
      })
    },
    updateUser() {
      API.updateUser(this.user, (r) => {
          location.reload()
  
      })
    }
  },
  created() {
    this.getUser()
  }
}
</script>
  
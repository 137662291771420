import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faArrowLeft, faBars, faCreditCard, faEnvelope, faHome, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faBars, faArrowLeft, faRightFromBracket, faCreditCard, faEnvelope)

let app=createApp(App)
app.config.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

app.component("font-awesome-icon", FontAwesomeIcon).use(router).mount("#app");// main.js
